<template>
  <h1 class="text-2xl font-bold mb-4">Career</h1>
  <div v-for="work in works" :key="work.id">
    <career-list-item :work="work"></career-list-item>
  </div>
  <h2 class="text-2xl font-bold mb-4">Volunteering</h2>
  <div
    v-for="volunteeringActivity in volunteeringActivities"
    :key="volunteeringActivity.id"
  >
    <career-list-item :work="volunteeringActivity"></career-list-item>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CareerListItem from "@/components/CareerListItem.vue";

@Options({
  components: {
    CareerListItem,
  },
})
export default class Career extends Vue {
  volunteeringActivities = [
    {
      id: 2,
      company: "LaravelCph meetup",
      logo: "/img/volunteering/laravel-cph.jpg",
      position: "Main organizer",
      description:
        "Helped the community of Laravel developers in Copenhagen to grow by organizing technical events and speaking at a few. This way, I could help people share and learn from each other." +
        "<br /><br />Even though Laravel was not the main topic I was interested in, this activity helped me improve my managerial and soft skills." +
        "<br /><br />More info: meetup.com/laravel-cph",
      contractType: "",
      duration: "2018 - 2020",
      location: "Copenhagen, Denmark",
    },
    {
      id: 1,
      company: "Københavns hovedbibliotek",
      logo: "/img/volunteering/cph-lib.jpg",
      position: "IT-savvy @ IT-Café",
      description:
        "I was a member of a team of volunteers interested in IT, had the ability and desire to teach, and want to engage in an unpaid community around the municipality's IT-café. However, I was the only English-speaking volunteer. 😃" +
        "<br /><br />In addition, I helped the main library of Copenhagen to improve one of their existing solutions used by the municipality, teachers, and students." +
        "<br /><br />A few months after starting volunteering at KBH IT-café, I moved to Aarhus.",
      contractType: "",
      duration: "2017",
      location: "Copenhagen, Denmark",
    },
  ];

  works = [
    {
      id: 19,
      company: "Kodlot",
      logo: "/img/career/kodlot.jpeg",
      position: "Solutions Architect and Cloud Engineering Consultant",
      description:
        "<b>Technologies:</b> AWS, Python, TypeScript, Gitlab, etc.<br />" +
        "Implementing efficient and secure ETL pipelines and backend solutions using Python, TypeScript, etc.<br/>" +
        "Building Data Products and Data Platforms while utilizing cloud engineering best practices.<br/>" +
        "<ul class='list-disc list-outside pt-2'>" +
        "<li class='ml-6'>Website: <a href='https://kodlot.com/' target='_blank' class='link'>kodlot.com</a></li>" +
        "<li class='ml-6'>AWS APN (AWS Partner Network): <a href='https://partners.amazonaws.com/partners/0010h00001ifABYAA2/' target='_blank' class='link'>partners.amazonaws.com</a></li>" +
        "<li class='ml-6'>AWS IQ - Pooyan: <a href='https://iq.aws.amazon.com/e/pooyan' target='_blank' class='link'>iq.aws.amazon.com/e/pooyan</a></li>" +
        "<li class='ml-6'>AWS IQ - Kodlot: <a href='https://iq.aws.amazon.com/c/kodlot' target='_blank' class='link'>iq.aws.amazon.com/c/kodlot</a></li>" +
        "</ul>",
      contractType: "",
      duration: "2024 - now",
      location: "Copenhagen, Denmark",
    },
    {
      id: 18,
      company: "Yanovation",
      logo: "/img/career/yanovation.png",
      position: "Founder",
      description:
        "The company is established in Estonia (Tallinn) but is not operational due to EU/Danish regulations for non-EU citizens. " +
        "<br />We provide different IT solutions to make life easier for individuals and businesses." +
        "<br />The company holds the Intellectual Property (IP) rights of our digital products." +
        "<ul class='list-disc list-outside pt-2'>" +
        "<li class='ml-6'>Website: <a href='https://yanovation.com/' target='_blank' class='link'>yanovation.com</a></li>" +
        "</ul>",
      contractType: "",
      duration: "2023 - now",
      location: "Tallinn, Estonia",
    },
    {
      id: 17,
      company: "Career Break",
      logo: "/img/career/career_break.svg",
      position: "Professional development",
      description:
        "During this time, I successfully developed a few IT solutions to make life easier for people, but unfortunately, I did not have permission to make money out of them due to complicated EU regulations for non-EU citizens. After a few months of exploring a few options in different countries, I finally found a way to establish the company in another European country. Currently waiting for the paperworks." +
        "<br /><br /><span class='font-semibold'>Closed source projects:</span>" +
        "<ul class='list-disc list-outside pt-2'>" +
        "<li class='ml-6'><a target='_blank' class='link' href='https://m2text.com'>M2Text</a> (disabled - cost saving)</li>" +
        "<li class='ml-6'><a target='_blank' class='link' href='https://speakm8.com'>speakm8.com</a> (disabled - cost saving)</li>" +
        "<li class='ml-6'><a target='_blank' class='link' href='https://digiwolk.com'>digiwolk.com</a> (disabled - cost saving)</li>" +
        "<li class='ml-6'><a target='_blank' class='link' href='https://chrome.google.com/webstore/detail/fjecechgnepfeonincjkmikllpjdjbgm/'>Write Assistant</a> (Chrome Extension - needed to establish a company before it could pass the Google review)</li>" +
        "</ul>" +
        "<br /><span class='font-semibold'>OSS projects:</span>" +
        "<ul class='list-disc list-outside pt-2'>" +
        "<li class='ml-6'><a target='_blank' class='link' href='https://github.com/marketplace/actions/oss-license-auditor'>github.com/marketplace/actions/oss-license-auditor</a></li>" +
        "<li class='ml-6'><a target='_blank' class='link' href='https://github.com/digi-wolk/oss-license-auditor'>github.com/digi-wolk/oss-license-auditor</a></li>" +
        "<li class='ml-6'><a target='_blank' class='link' href='https://github.com/digi-wolk/olaudit-action'>github.com/digi-wolk/olaudit-action</a></li>" +
        "<li class='ml-6'><a target='_blank' class='link' href='https://github.com/digi-wolk/rss-feed-reader'>github.com/digi-wolk/rss-feed-reader</a></li>" +
        "<li class='ml-6'><a target='_blank' class='link' href='https://github.com/prazian/open-ai-image-generate'>github.com/prazian/open-ai-image-generate</a></li>" +
        "</ul>" +
        "<br /><span class='font-semibold'>Articles:</span>" +
        "<ul class='list-disc list-outside pt-2'>" +
        "<li class='ml-6'><a class='link' href='https://www.pooyan.info/articles'>pooyan.info/articles</a></li>" +
        "<li class='ml-6'><a target='_blank' class='link' href='https://medium.com/@pooyan_razian'>medium.com/@pooyan_razian</a></li>" +
        "</ul>",
      contractType: "",
      duration: "2022-2023",
      location: "Copenhagen, Denmark",
    },
    {
      id: 16,
      company: "Kompasbank",
      logo: "/img/career/kombasbank.jpeg",
      position: "DevSecOps engineer",
      description:
        "Successfully delivered a few process automation projects that increased visibility and significantly reduced costs and dependencies." +
        "<br /><br />I decided to quit the job and continue building a new product.",
      contractType: "",
      duration: "2023",
      location: "Vedbæk, Denmark",
    },
    {
      id: 15,
      company: "adesso SE",
      logo: "/img/career/adesso.jpeg",
      position: "AWS cloud architect",
      description:
        "Purple Scout joined adesso group about a month after I joined the company. My responsibilities inclueded converting code into cloud, migration of existing solutions to cloud, and applying AWS best practices." +
        "<br /><br /><span class='font-semibold'>Projects:</span>" +
        "<ul class='list-disc list-outside pt-2'>" +
        "<li class='ml-6'>Migrated a Nordic asset-management platform from an old solution with zero automation and a very limited level of visibility to a fully-automated scalable infrastructure on AWS with a high level of visibility and improved security. I introduced version controlling for the application development and infrastructure management, GitOps, IaC/IaS, CI/CD pipelines, and using IaC/IaS, I configured WAF, SSO, databases on RDS, Athena, Backup, etc. I also built proper dashboards and alerts.</li>" +
        "<li class='ml-6'>Helped an AI-based Nordic business to migrate from a monolith JAVA application with manual processes to a microservice architecture on AWS with automation. The process included helping the client to improve software development processes (release and deployment strategy, etc.), building CI/CD pipelines, automating the infrastructure management using Infrastructure as Software (IoS/IoC, mainly built by CDK in Java, but also used TypeScript for the first POC), improving visibility, migrating from EC2 and containerizing existing solutions on AWS.</li>" +
        "</ul>",
      contractType: "full time",
      duration: "2022",
      location: "Copenhagen, Denmark",
    },
    {
      id: 14,
      company: "Purple Scout",
      logo: "/img/career/purple_scout.jpeg",
      position: "AWS cloud architect",
      description:
        "Purple Scout joined adesso group, one month after I joined the company.<br />" +
        "Won a security hackathon on my first month of employment!",
      contractType: "full time",
      duration: "2022",
      location: "Copenhagen, Denmark",
    },
    {
      id: 13,
      company: "Accenture",
      logo: "/img/career/accenture.png",
      position: "Solution consultant",
      description:
        "I was working on a variety of projects in different countries with different technologies.",
      contractType: "full time",
      duration: "2021 - 2022",
      location: "Copenhagen, Denmark",
    },
    {
      id: 12,
      company: "Peytz & Co. A/S",
      logo: "/img/career/peytz_co.png",
      position: "Senior software developer",
      description:
        "I was hired on a temporary contract to mainly work on one project, but in addition to that I also evaluated, initialized, and handed over another one as well.",
      contractType: "contract",
      duration: "2020",
      location: "Copenhagen, Denmark",
    },
    {
      id: 11,
      company: "EasyTranslate A/S",
      logo: "/img/career/easy_translate.png",
      position:
        "Tech lead <span class='lowercase'>(unofficially)</span><br /><span class='text-sm text-gray-500'>Senior software developer</span>",
      description:
        "After one month, I unofficially got promoted to become the Tech Lead of the company due to organizational changes." +
        "<br /><br />Achievements:\n" +
        "<ul class='pl-4'>" +
        "<li class='list-disc'>MVP of EasyOrder: easyorder.easytranslate.com\n" +
        "<li class='list-disc'>MVP of a custom marketing solution\n" +
        "<li class='list-disc'>Improving automation\n" +
        "<li class='list-disc'>Used DDD to define the architecture\n" +
        "<li class='list-disc'>Improving visibility using Elastic stack\n" +
        "<li class='list-disc'>Applied scrum best practices together with the Scrum master\n" +
        "<li class='list-disc'>2 successful hirings\n" +
        "</ul>\n" +
        "<br />AWS, Docker, ECS, CircleCi, PHP, Laravel, Symfony, Yii2, MongoDB, MySQL, Elastic stack, ...",
      contractType: "full time",
      duration: "2019",
      location: "Copenhagen, Denmark",
    },
    {
      id: 10,
      company: "Cobiro ApS",
      logo: "/img/career/cobiro.png",
      position: "Senior software developer",
      description: "",
      contractType: "",
      duration: "2018",
      location: "Copenhagen, Denmark",
    },
    {
      id: 1,
      company: "EasyInspect",
      logo: "/img/career/easy_inspect.png",
      position: "Senior software developer",
      description: "",
      contractType: "",
      duration: "2018",
      location: "Copenhagen, Denmark",
    },
    {
      id: 9,
      company: "Response Finance ApS",
      logo: "/img/career/response_finance.png",
      position: "Lead Software Developer",
      description: "",
      contractType: "",
      duration: "2017",
      location: "Aarhus, Denmark",
    },
    {
      id: 8,
      company: "Bærnholdt ApS",
      logo: "/img/career/barnholdt.png",
      position: "Software developer",
      description: "",
      contractType: "",
      duration: "",
      location: "Copenhagen, Denmark",
    },
    {
      id: 7,
      company: "Easi'r",
      logo: "/img/career/easir.png",
      position: "Software developer",
      description: "",
      contractType: "",
      duration: "",
      location: "Aarhus, Denmark",
    },
    {
      id: 6,
      company: "Gemalto (Thales)",
      logo: "/img/career/gemalto_thales.png",
      position: "Senior Operations Support Engineer",
      description:
        "As an external employee hired by Faraz Mahour company for the EMEA branch of Gemalto (now called Thales)," +
        "I was responsible for local OTA S@T/Crypto and Smart Message solutions.",
      contractType: "full time",
      duration: "2015 - 2016",
      location: "Tehran, Iran",
    },
    {
      id: 5,
      company: "M-Theory",
      logo: "/img/career/m-theory.png",
      position: "Senior Software Engineer",
      description:
        "As an external employee hired by Faraz Mahour company for the EMEA branch of Gemalto, I was responsible for local OTA S@T/Crypto and Smart Message solutions, which were telecom-VAS solutions. My daily tasks included operations, enhancements, data analyzing, and adding new features to the product. Building web/telecom VAS solutions for Faraz Mahour. In addition, I was responsible for the new products built for M-Theory.",
      contractType: "full time",
      duration: "2014 - 2016",
      location: "Tehran, Iran",
    },
    {
      id: 4,
      company: "Hamrahe Aval (MCI)",
      logo: "/img/career/hamrahe_aval_mci.png",
      position: "BSS software engineer",
      description:
        "I was hired as an external employee." +
        "<br /><br />Services:" +
        "<ol class='pl-4'>" +
        "<li class='list-disc'>Telecom Rating (Inhouse, post-paid billing upstream)</li>" +
        "<li class='list-disc'>Telecom International Roaming (Vendor)</li>" +
        "<li class='list-disc'>Telecom National Roaming (Vendor)</li>" +
        "<li class='list-disc'>Telecom Mediation (Vendor)</>" +
        "</ol>" +
        "<br />" +
        "My responsibilities:" +
        "<ol class='pl-4'>" +
        "<li class='list-disc'>Application development (Rating)</>" +
        "<li class='list-disc'>Project coordination (Rating and Roaming)</li>" +
        "<li class='list-disc'>Application support and maintenance (Rating, Roaming, Mediation) (Level 1 support)</li>" +
        "</ol>",
      contractType: "full time",
      duration: "2013 - 2014",
      location: "Tehran, Iran",
    },
    {
      id: 3,
      company: "Aryahamrah Co. (AHS)",
      logo: "/img/career/aryahamrah_samaneh_ahs.png",
      position: "Unix Administrator",
      description:
        "Mainly working on the MTN-Irancell project with millions of customers and billions of daily transactions.",
      contractType: "full time",
      duration: "2012 - 2013",
      location: "Tehran, Iran",
    },
    {
      id: 2,
      company: "Mobina",
      logo: "/img/career/other.png",
      position: "System Administrator",
      description:
        "https://mobina.net" +
        "<br /><br />I was assigned on three projects as a system administrator consultant.",
      contractType: "full time",
      duration: "2011",
      location: "Tehran, Iran",
    },
    {
      id: 1,
      company: "Freelancer",
      logo: "/img/career/other.png",
      position: "PHP developer, Linux web hosting",
      description:
        "Back-end PHP web application development" +
        "<br />Front-end HTML/HTML5, Javascript, CSS/CSS3 development" +
        "<br />Database design" +
        "<br />Linux web hosting",
      contractType: "",
      duration: "2005 - 2009",
      location: "Tehran, Iran",
    },
  ];
}
</script>
