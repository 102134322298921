
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    work: {},
  },
})
export default class CareerListItem extends Vue {
  work!: unknown;
}
