<template>
  <div class="flex flex-col-3 mb-8 md:mb-0">
    <div class="flex-shrink-0 w-14 md:w-16">
      <img class="rounded-md shadow-md" :src="work.logo" />
    </div>
    <div class="flex-grow pr-2 pl-5 md:pl-8">
      <h3>
        <span class="inline text-xl font-bold">
          {{ work.company }}
        </span>
        <span
          v-if="work.contractType"
          class="hidden md:inline text-xl text-gray-400 px-1"
          >-</span
        >
        <span
          v-if="work.contractType"
          class="hidden md:inline text-xs text-gray-400 font-bold uppercase"
          >{{ work.contractType }}</span
        >
      </h3>
      <p class="text-md font-semibold capitalize" v-html="work.position"></p>
    </div>
    <div class="hidden md:block pl-4 flex-shrink-0 text-right">
      <p class="font-semibold text-sm text-gray-400">{{ work.duration }}</p>
      <p class="font-semibold text-sm text-gray-400">{{ work.location }}</p>
    </div>
  </div>
  <div class="hidden md:block ml-24 mb-8">
    <p
      class="text-md pt-2 leading-relaxed leading-6 font-nunito-sans"
      v-html="work.description"
    ></p>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    work: {},
  },
})
export default class CareerListItem extends Vue {
  work!: unknown;
}
</script>
